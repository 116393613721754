const adhlogin = {
  namespaced: true,
  state: {
    logLog: '',
    pwdLog: '',
    idFilogin: '',
    idAdhlogin: '',
    idFirep: '',
  },
  mutations: {
    resetState(state) {
      state.logLog = '';
      state.pwdLog = '';
      state.idFilogin = '';
      state.idAdhlogin = '';
      state.idFirep = '';
    },
    setLogLog(state, value) {
      state.logLog = value;
    },
    setPwdLog(state, value) {
      state.pwdLog = value;
    },
    setIdFilogin(state, value) {
      state.idFilogin = value;
    },
    setIdAdhlogin(state, value) {
      state.idAdhlogin = value;
    },
    setIdFirep(state, value) {
      state.idFirep = value;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
  },
};

export default adhlogin;
