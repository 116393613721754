import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import adhlogin from './adhlogin';
import gadherent from './gadherent';
// Fonction pour retourner l'état initial
const getAdminDefaultState = () => ({
  defaultState: {
    session: null,
  },
});

export default createStore({
  state: getAdminDefaultState(),
  getters: {},
  mutations: {
     // Met à jour la session
     setSession(state, value) {
      state.defaultState.session = value;
    },
    // Réinitialise tout l'état
    resetState(state) {
      Object.assign(state, getAdminDefaultState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
  },
  modules: {
    adhlogin,
    gadherent,
  },
  plugins: [createPersistedState()],
});
