import { createRouter, createWebHistory } from 'vue-router'
import PageIndex from '@/views/Index.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: PageIndex
  },
  {
    path: '/TableauDeBord',
    name: 'TableauDeBord',
    component: () => import('../views/TableauDeBord.vue'),
  },
  {
    path: '/MaintApplicatif',
    name: 'MaintApplicatif',
    component: () => import('../views/MaintApplicatif.vue'),
  },
  {
    path: '/MaintModule',
    name: 'MaintModule',
    component: () => import('../views/MaintModule.vue'),
  },
  {
    path: '/MaintAdhLogin',
    name: 'MaintAdhLogin',
    component: () => import('../views/MaintAdhLogin.vue'),
  },
  {
    path: '/MaintAdhCli',
    name: 'MaintAdhCli',
    component: () => import('../views/MaintAdhCli.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
