import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import './registerServiceWorker'
import router from './router'
import "./css/styles.css";
import StoragePlugin from 'vue-web-storage'
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; 

const app = createApp(App).use(router).use(store)

// .use(SimpleTypeahead)
.use(StoragePlugin, {
  prefix: 'sess_', // Préfixe des clés de stockage, par défaut 'app_'
  drivers: ['session', 'local'], // Type de stockage utilisé
});;
 app.config.globalProperties.$swal = Swal;
 app.mount('#app');
