const gadherent = {
  namespaced: true,
  state: {
    insAdh: '',
    idGadherent: '',
    nomAdh: '',
  },
  mutations: {
    resetState(state) {
      state.insAdh = '';
      state.idGadherent = '';
      state.nomAdh = '';
    },
    setInsAdh(state, value) {
      state.insAdh = value;
    },
    setIdGadherent(state, value) {
      state.idGadherent = value;
    },
    setNomAdh(state, value) {
      state.nomAdh = value;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
  },
};

export default gadherent;
