<template>
  <header class="navAfter">
    <div class="container">
      <a class="logoHeader" title="retour à l'accueil">
        <img src="../assets/svg/logo/logo-admin-bleu-blanc.svg" />
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: "entete",
};
</script>
