<template>
  <entete></entete>
  <main>
    <form class="container">
      <div class="column mdRow rowTextuel">
        <div class="colIntro col100 colMd60">
          <h1>Administration Autoref</h1>
          <p>Choix de votre instance de connexion</p>
        </div>
      </div>
      <div class="rowInputs">
        <!-- LISTE UL LI POUR L'INSTANCE -->
        <div class="row rowLabel">
          <label for="instance">Instance</label>
        </div>
        <div class="column mdRow rowForm">
          <div class="inputGroupInstance" @click="toggleDropdown()" ref="dropdown">
            <input
              type="text"
              class="formControlInstance"
              v-model="selectNomAdh"
              readonly
            />
            <input type="hidden" v-model="selectInstance" />
            <input type="hidden" v-model="selectId_gadherent" />
            <button type="button" class="boutonInstance">
              <i
                class="fa-solid fa-angle-down"
                :class="{ rotated: isDropdownVisible }"
              ></i>
            </button>
            <ul class="dropdownMenu" v-show="isDropdownVisible">
              <li v-for="(adh, index) in tabAdh" :key="index" @click="selectOption(adh)">
                {{ adh.NOM_ADH }} [ {{ adh.INS_ADH }} ]
              </li>
            </ul>
          </div>
        </div>
        <button
          type="button"
          id="valider"
          ref="valider"
          class="btn btnPlain btnValider"
          @click="validateInstance"
        >
          Valider
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import entete from "@/components/comp.entete.vue";

export default {
  name: "ChoixInstance",
  props: {
    tabAdh: {
      type: Array,
      required: true,
    },
  },
  components: {
    entete,
  },
  data() {
    return {
      selectInstance: "Choix de votre instance de connexion", // Contient l'objet sélectionné
      isDropdownVisible: false,
      selectNomAdh: "",
      selectId_gadherent: "",
    };
  },
  computed: {
    // Retourne le texte à afficher pour l'option sélectionnée
    selectedOptionText() {
      return this.selectedOption
        ? `${this.selectedOption.NOM_ADH} ${this.selectedOption.INS_ADH}`
        : "";
    },
  },
  mounted() {
    document.addEventListener("click", this.closeDropdownOnOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdownOnOutsideClick);
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    closeDropdownOnOutsideClick(event) {
      const dropdown = this.$refs.dropdown;
      if (dropdown && !dropdown.contains(event.target)) {
        this.isDropdownVisible = false;
      }
    },
    selectOption(option) {
      if (option && option.NOM_ADH && option.INS_ADH) {
        this.selectInstance = option.INS_ADH;
        this.selectNomAdh = option.NOM_ADH;
        this.selectId_gadherent = option.ID_GADHERENT;
        const dropdown = this.$refs.dropdown;
        if (dropdown && !dropdown.contains(event.target)) {
          this.isDropdownVisible = false;
        }
      } else {
        console.error("Option invalide sélectionnée :", option);
      }
    },
    validateInstance() {
      if (!this.selectNomAdh) {
        alert("Veuillez sélectionner une instance.");
      } else {
        this.$store.commit("gadherent/setInsAdh", this.selectInstance);
        this.$store.commit("gadherent/setIdGadherent", this.selectId_gadherent);
        this.$store.commit("gadherent/setNomAdh", this.selectNomAdh);
        this.$router.push({ name: "TableauDeBord" });
      }
    },
  },
};
</script>
